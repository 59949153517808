import React from 'react';
import { connect } from '@optum-uhone-hmkts/rise';
import { AppState } from '../../reducers';
import { getLeadAccountSummary } from '../../selectors/lead_selectors';
import { AccountSummary } from '../../reducers/LeadReducer';
import { useStylesLeads } from '../../utilities/lead_util';
import { Strings } from '../../assets/common/strings';
import { Box, Table, TableBody, TableCell, TableContainer, TableRow, TableFooter } from '@material-ui/core';
import classNames from 'classnames';

interface StateProps {
    accountSummary: AccountSummary;
}

interface ComponentProps {
    style?: React.CSSProperties;
}

type Props = StateProps & ComponentProps;

const _WeeklyLiveTransferSummary: React.FC<Props> = (props) => {
    const { accountSummary, style } = props;
    const classes = useStylesLeads();
    const tableRowStyle = classNames(classes.tableFixedColumnWidth, classes.compactRow);
    const tableCellStyle = classNames(classes.leadAccountSummaryFieldsValueColor, classes.tableCell, classes.compactCell);
    const tableCellFooterStyle = classNames(classes.totalLeadIntakeHeadingColor, classes.tableCell, classes.compactCell);

    return (
        <Box className={classes.leadAccountSummaryBox} style={style}>
            <div className={classes.leadAccountSummaryLabel}>
                <TableContainer>
                    <Table className={classes.tableFixedColumnWidth} aria-label="Weekly Live Transfer Summary">

                        <TableBody>
                            <TableRow className={tableRowStyle}>
                                <TableCell className={tableCellStyle}>
                                    {accountSummary.TotalCalls}
                                </TableCell>

                                <TableCell  className={tableCellStyle}>
                                    {accountSummary.TotalAcceptedCalls}
                                </TableCell>

                                <TableCell className={tableCellStyle}>
                                    {accountSummary.TotalWonCalls}
                                </TableCell>
                            </TableRow>
                        </TableBody>

                        <TableFooter>
                            <TableRow>
                                {
                                    Object.values(Strings.WeeklyLiveTransferSummaryHeading).map((value) =>
                                        <TableCell align="center" className={tableCellFooterStyle}>{value}</TableCell>)
                                }
                            </TableRow>
                        </TableFooter>

                    </Table>
                </TableContainer>
            </div>
        </Box>
    );
};

const mapStateToProps = (state: AppState): StateProps => ({
    accountSummary: getLeadAccountSummary(state),
});

export const WeeklyLiveTransferSummary = connect(mapStateToProps, {}, true)(_WeeklyLiveTransferSummary) as React.ComponentClass<ComponentProps>;
